<script>
  import pokemon from "./pokemon.js";

  let name = "Tabitha";

  let types = new Set();
  let selectedType = "all";
  pokemon.forEach((p) => {
    p.types.forEach((t) => {
      types.add(t);
    });
  });

  $: filteredPokemon = pokemon.filter(
    (p) => selectedType === "all" || p.types.includes(selectedType)
  );
</script>

<div class="pot">
  <h1>{name}'s Pokédex!</h1>
  <label
    >Type:
    <select bind:value={selectedType}>
      <option value="all">all</option>
      {#each Array.from(types).sort() as type}
        <option value={type}>{type}</option>
      {/each}
    </select>
  </label>

  {#each filteredPokemon as p}
    <h2>#{p.id} {p.name}</h2>
    <img height="96" width="96" src="/pokemon/{p.id}.png" />
    <p>{p.description}</p>
    <dl>
      <dt>Height</dt>
      <dd>{p.height / 10} meters</dd>
      <dt>Weight</dt>
      <dd>{p.weight / 10} kg</dd>
      <dt>Type</dt>
      {#each p.types as type}
        <dd class="type">{type}</dd>
      {/each}
    </dl>
  {/each}
</div>

<style>
  h2,
  dd.type {
    text-transform: capitalize;
  }

  dt {
    font-weight: bold;
  }

  .pot {
    background: red;
    color: white;
    padding: 1rem;
  }

  img {
    background-color: white;
    border: 4px solid black;
    border-radius: 96px;
  }
</style>
