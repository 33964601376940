export default [
  {
    id: 1,
    name: "bulbasaur",
    types: ["grass", "poison"],
    height: 7,
    weight: 69,
    description:
      "For some time after its birth, it grows by gaining nourishment from the seed on its back.",
  },
  {
    id: 2,
    name: "ivysaur",
    types: ["grass", "poison"],
    height: 10,
    weight: 130,
    description:
      "When the bud on its back starts swelling, a sweet aroma wafts to indicate the flowers coming bloom.",
  },
  {
    id: 3,
    name: "venusaur",
    types: ["grass", "poison"],
    height: 20,
    weight: 1000,
    description:
      "After a rainy day, the flower on its back smells stronger. The scent attracts other Pokémon.",
  },
  {
    id: 4,
    name: "charmander",
    types: ["fire"],
    height: 6,
    weight: 85,
    description:
      "The fire on the tip of its tail is a measure of its life. If healthy, its tail burns intensely.",
  },
  {
    id: 5,
    name: "charmeleon",
    types: ["fire"],
    height: 11,
    weight: 190,
    description:
      "In the rocky mountains where Charmeleon live, their fiery tails shine at night like stars.",
  },
  {
    id: 6,
    name: "charizard",
    types: ["fire", "flying"],
    height: 16,
    weight: 905,
    description:
      "It is said that Charizards fire burns hotter if it has experienced harsh battles.",
  },
  {
    id: 7,
    name: "squirtle",
    types: ["water"],
    height: 5,
    weight: 90,
    description:
      "It shelters itself in its shell then strikes back with spouts of water at every opportunity.",
  },
  {
    id: 8,
    name: "wartortle",
    types: ["water"],
    height: 10,
    weight: 225,
    description:
      "It is said to live 10,000 years. Its furry tail is popular as a symbol of longevity.",
  },
  {
    id: 9,
    name: "blastoise",
    types: ["water"],
    height: 16,
    weight: 855,
    description:
      "The jets of water it spouts from the rocket cannons on its shell can punch through thick steel.",
  },
  {
    id: 10,
    name: "caterpie",
    types: ["bug"],
    height: 3,
    weight: 29,
    description:
      "It releases a stench from its red antenna to repel enemies. It grows by molting repeatedly.",
  },
  {
    id: 11,
    name: "metapod",
    types: ["bug"],
    height: 7,
    weight: 99,
    description:
      "A steel-hard shell protects its tender body. It quietly endures hardships while awaiting evolution.",
  },
  {
    id: 12,
    name: "butterfree",
    types: ["bug", "flying"],
    height: 11,
    weight: 320,
    description:
      "It loves the honey of flowers and can locate flower patches that have even tiny amounts of pollen.",
  },
  {
    id: 13,
    name: "weedle",
    types: ["poison", "bug"],
    height: 3,
    weight: 32,
    description:
      "It eats its weight in leaves every day. It fends off attackers with the needle on its head.",
  },
  {
    id: 14,
    name: "kakuna",
    types: ["poison", "bug"],
    height: 6,
    weight: 100,
    description:
      "While awaiting evolution, it hides from predators under leaves and in nooks of branches.",
  },
  {
    id: 15,
    name: "beedrill",
    types: ["poison", "bug"],
    height: 10,
    weight: 295,
    description:
      "Its best attack involves flying around at high speed, striking with poison needles, then flying off.",
  },
  {
    id: 16,
    name: "pidgey",
    types: ["flying", "normal"],
    height: 3,
    weight: 18,
    description:
      "It is docile and prefers to avoid conflict. If disturbed, however, it can ferociously strike back.",
  },
  {
    id: 17,
    name: "pidgeotto",
    types: ["flying", "normal"],
    height: 11,
    weight: 300,
    description:
      "It flies over its wide territory in search of prey, downing it with its highly developed claws.",
  },
  {
    id: 18,
    name: "pidgeot",
    types: ["flying", "normal"],
    height: 15,
    weight: 395,
    description:
      "It flies over its wide territory in search of prey, downing it with its highly developed claws.",
  },
  {
    id: 19,
    name: "rattata",
    types: ["normal"],
    height: 3,
    weight: 35,
    description:
      "It searches for food all day. It gnaws on hard objects to wear down its fangs, which grow constantly during its lifetime.",
  },
  {
    id: 20,
    name: "raticate",
    types: ["normal"],
    height: 7,
    weight: 185,
    description:
      "With its long fangs, this surprisingly violent Pokémon can gnaw away even thick concrete with ease.",
  },
  {
    id: 21,
    name: "spearow",
    types: ["flying", "normal"],
    height: 3,
    weight: 20,
    description:
      "It flaps its small wings busily to fly. Using its beak, it searches in grass for prey.",
  },
  {
    id: 22,
    name: "fearow",
    types: ["flying", "normal"],
    height: 12,
    weight: 380,
    description:
      "It has the stamina to fly all day on its broad wings. It fights by using its sharp beak.",
  },
  {
    id: 23,
    name: "ekans",
    types: ["poison"],
    height: 20,
    weight: 69,
    description:
      "It sneaks through grass without making a sound and strikes unsuspecting prey from behind.",
  },
  {
    id: 24,
    name: "arbok",
    types: ["poison"],
    height: 35,
    weight: 650,
    description:
      "The pattern on its belly is for intimidation. It constricts foes while they are frozen in fear.",
  },
  {
    id: 25,
    name: "pikachu",
    types: ["electric"],
    height: 4,
    weight: 60,
    description:
      "It occasionally uses an electric shock to recharge a fellow Pikachu that is in a weakened state.",
  },
  {
    id: 26,
    name: "raichu",
    types: ["electric"],
    height: 8,
    weight: 300,
    description:
      "Its tail discharges electricity into the ground, protecting it from getting shocked.",
  },
  {
    id: 27,
    name: "sandshrew",
    types: ["ground"],
    height: 6,
    weight: 120,
    description:
      "It digs deep burrows to live in. When in danger, it rolls up its body to withstand attacks.",
  },
  {
    id: 28,
    name: "sandslash",
    types: ["ground"],
    height: 10,
    weight: 295,
    description:
      "The spikes on its body are made up of its hardened hide. It rolls up and attacks foes with its spikes.",
  },
  {
    id: 29,
    name: "nidoran",
    types: ["poison"],
    height: 4,
    weight: 70,
    description:
      "While it does not prefer to fight, even one drop of the poison it secretes from barbs can be fatal.",
  },
  {
    id: 30,
    name: "nidorina",
    types: ["poison"],
    height: 8,
    weight: 200,
    description:
      "When it senses danger, it raises all the barbs on its body. These barbs grow slower than Nidorinos.",
  },
  {
    id: 31,
    name: "nidoqueen",
    types: ["poison", "ground"],
    height: 13,
    weight: 600,
    description:
      "Its entire body is armored with hard scales. It will protect the young in its burrow with its life.",
  },
  {
    id: 32,
    name: "nidoran",
    types: ["poison"],
    height: 5,
    weight: 90,
    description:
      "It scans its surroundings by raising its ears out of the grass. Its toxic horn is for protection.",
  },
  {
    id: 33,
    name: "nidorino",
    types: ["poison"],
    height: 9,
    weight: 195,
    description:
      "It has a violent disposition and stabs foes with its horn, which oozes poison upon impact.",
  },
  {
    id: 34,
    name: "nidoking",
    types: ["poison", "ground"],
    height: 14,
    weight: 620,
    description:
      "One swing of its mighty tail can snap a telephone pole as if it were a matchstick.",
  },
  {
    id: 35,
    name: "clefairy",
    types: ["fairy"],
    height: 6,
    weight: 75,
    description:
      "On nights with a full moon, Clefairy gather from all over and dance. Bathing in moonlight makes them float.",
  },
  {
    id: 36,
    name: "clefable",
    types: ["fairy"],
    height: 13,
    weight: 400,
    description:
      "Their ears are sensitive enough to hear a pin drop from over a mile away, so theyre usually found in quiet places.",
  },
  {
    id: 37,
    name: "vulpix",
    types: ["fire"],
    height: 6,
    weight: 99,
    description:
      "As each tail grows, its fur becomes more lustrous. When held, it feels slightly warm.",
  },
  {
    id: 38,
    name: "ninetales",
    types: ["fire"],
    height: 11,
    weight: 199,
    description:
      "Each of its nine tails is imbued with supernatural power, and it can live for a thousand years.",
  },
  {
    id: 39,
    name: "jigglypuff",
    types: ["fairy", "normal"],
    height: 5,
    weight: 55,
    description:
      "Looking into its cute, round eyes makes it start singing a song so pleasant listeners cant help but fall asleep.",
  },
  {
    id: 40,
    name: "wigglytuff",
    types: ["fairy", "normal"],
    height: 10,
    weight: 120,
    description:
      "Its fine fur feels so pleasant, those who accidentally touch it cannot take their hands away.",
  },
  {
    id: 41,
    name: "zubat",
    types: ["flying", "poison"],
    height: 8,
    weight: 75,
    description:
      "It does not need eyes, because it emits ultrasonic waves to check its surroundings while it flies.",
  },
  {
    id: 42,
    name: "golbat",
    types: ["flying", "poison"],
    height: 16,
    weight: 550,
    description:
      "Flitting around in the dead of night, it sinks its fangs into its prey and drains a nearly fatal amount of blood.",
  },
  {
    id: 43,
    name: "oddish",
    types: ["poison", "grass"],
    height: 5,
    weight: 54,
    description:
      "It often plants its root feet in the ground during the day and sows seeds as it walks about at night.",
  },
  {
    id: 44,
    name: "gloom",
    types: ["poison", "grass"],
    height: 8,
    weight: 86,
    description:
      "The honey it drools from its mouth smells so atrocious, it can curl noses more than a mile away.",
  },
  {
    id: 45,
    name: "vileplume",
    types: ["poison", "grass"],
    height: 12,
    weight: 186,
    description:
      "Its petals are the largest in the world. As it walks, it scatters extremely allergenic pollen.",
  },
  {
    id: 46,
    name: "paras",
    types: ["grass", "bug"],
    height: 3,
    weight: 54,
    description:
      "Mushrooms named tochukaso grow on its back. They grow along with the host Paras.",
  },
  {
    id: 47,
    name: "parasect",
    types: ["grass", "bug"],
    height: 10,
    weight: 295,
    description:
      "A mushroom grown larger than the hosts body controls Parasect. It scatters poisonous spores.",
  },
  {
    id: 48,
    name: "venonat",
    types: ["poison", "bug"],
    height: 10,
    weight: 300,
    description:
      "Its big eyes are actually clusters of tiny eyes. At night, its kind is drawn by light.",
  },
  {
    id: 49,
    name: "venomoth",
    types: ["poison", "bug"],
    height: 15,
    weight: 125,
    description:
      "It flutters its wings to scatter dustlike scales. The scales leach toxins if they contact skin.",
  },
  {
    id: 50,
    name: "diglett",
    types: ["ground"],
    height: 2,
    weight: 8,
    description:
      "A Pokémon that lives underground. Because of its dark habitat, it is repelled by bright sunlight.",
  },
  {
    id: 51,
    name: "dugtrio",
    types: ["ground"],
    height: 7,
    weight: 333,
    description:
      "Its three heads move alternately, driving it through tough soil to depths of over 60 miles.",
  },
  {
    id: 52,
    name: "meowth",
    types: ["normal"],
    height: 4,
    weight: 42,
    description:
      "It is nocturnal in nature. If it spots something shiny, its eyes glitter brightly.",
  },
  {
    id: 53,
    name: "persian",
    types: ["normal"],
    height: 10,
    weight: 320,
    description:
      "A very haughty Pokémon. Among fans, the size of the jewel in its forehead is a topic of much talk.",
  },
  {
    id: 54,
    name: "psyduck",
    types: ["water"],
    height: 8,
    weight: 196,
    description:
      "When headaches stimulate its brain cells, which are usually inactive, it can use a mysterious power.",
  },
  {
    id: 55,
    name: "golduck",
    types: ["water"],
    height: 17,
    weight: 766,
    description:
      "When its forehead shines mysteriously, Golduck can use the full extent of its power.",
  },
  {
    id: 56,
    name: "mankey",
    types: ["fighting"],
    height: 5,
    weight: 280,
    description:
      "It lives in treetop colonies. If one becomes enraged, the whole colony rampages for no reason.",
  },
  {
    id: 57,
    name: "primeape",
    types: ["fighting"],
    height: 10,
    weight: 320,
    description:
      "It grows angry if you see its eyes and gets angrier if you run. If you beat it, it gets even madder.",
  },
  {
    id: 58,
    name: "growlithe",
    types: ["fire"],
    height: 7,
    weight: 190,
    description:
      "Extremely loyal to its Trainer, it will bark at those who approach the Trainer unexpectedly and run them out of town.",
  },
  {
    id: 59,
    name: "arcanine",
    types: ["fire"],
    height: 19,
    weight: 1550,
    description:
      "The sight of it running over 6,200 miles in a single day and night has captivated many people.",
  },
  {
    id: 60,
    name: "poliwag",
    types: ["water"],
    height: 6,
    weight: 124,
    description:
      "Its skin is so thin, its internal organs are visible. It has trouble walking on its newly grown feet.",
  },
  {
    id: 61,
    name: "poliwhirl",
    types: ["water"],
    height: 10,
    weight: 200,
    description:
      "The spiral pattern on its belly subtly undulates. Staring at it gradually causes drowsiness.",
  },
  {
    id: 62,
    name: "poliwrath",
    types: ["fighting", "water"],
    height: 13,
    weight: 540,
    description:
      "With its extremely tough muscles, it can keep swimming in the Pacific Ocean without resting.",
  },
  {
    id: 63,
    name: "abra",
    types: ["psychic"],
    height: 9,
    weight: 195,
    description:
      "Using its psychic power is such a strain on its brain that it needs to sleep for 18 hours a day.",
  },
  {
    id: 64,
    name: "kadabra",
    types: ["psychic"],
    height: 13,
    weight: 565,
    description:
      "It stares at its silver spoon to focus its mind. It emits more alpha waves while doing so.",
  },
  {
    id: 65,
    name: "alakazam",
    types: ["psychic"],
    height: 15,
    weight: 480,
    description:
      "The spoons clutched in its hands are said to have been created by its psychic powers.",
  },
  {
    id: 66,
    name: "machop",
    types: ["fighting"],
    height: 8,
    weight: 195,
    description:
      "Though small in stature, it is powerful enough to easily heft and throw a number of Geodude at once.",
  },
  {
    id: 67,
    name: "machoke",
    types: ["fighting"],
    height: 15,
    weight: 705,
    description:
      "It happily carries heavy cargo to toughen up. It willingly does hard work for people.",
  },
  {
    id: 68,
    name: "machamp",
    types: ["fighting"],
    height: 16,
    weight: 1300,
    description:
      "Its four muscled arms slam foes with powerful punches and chops at blinding speed.",
  },
  {
    id: 69,
    name: "bellsprout",
    types: ["poison", "grass"],
    height: 7,
    weight: 40,
    description:
      "It prefers hot and humid environments. It is quick at capturing prey with its vines.",
  },
  {
    id: 70,
    name: "weepinbell",
    types: ["poison", "grass"],
    height: 10,
    weight: 64,
    description:
      "A Pokémon that appears to be a plant. It captures unwary prey by dousing them with a toxic powder.",
  },
  {
    id: 71,
    name: "victreebel",
    types: ["poison", "grass"],
    height: 17,
    weight: 155,
    description:
      "It pools in its mouth a fluid with a honey-like scent, which is really an acid that dissolves anything.",
  },
  {
    id: 72,
    name: "tentacool",
    types: ["poison", "water"],
    height: 9,
    weight: 455,
    description:
      "Because its body is almost entirely composed of water, it shrivels up if it is washed ashore.",
  },
  {
    id: 73,
    name: "tentacruel",
    types: ["poison", "water"],
    height: 16,
    weight: 550,
    description:
      "It extends its 80 tentacles to form an encircling poisonous net that is difficult to escape.",
  },
  {
    id: 74,
    name: "geodude",
    types: ["ground", "rock"],
    height: 4,
    weight: 200,
    description:
      "At rest, it looks just like a rock. Carelessly stepping on it will make it swing its fists angrily.",
  },
  {
    id: 75,
    name: "graveler",
    types: ["ground", "rock"],
    height: 10,
    weight: 1050,
    description:
      "It rolls on mountain paths to move. Once it builds momentum, no Pokémon can stop it without difficulty.",
  },
  {
    id: 76,
    name: "golem",
    types: ["ground", "rock"],
    height: 14,
    weight: 3000,
    description:
      "Even dynamite cant harm its hard, boulder-like body. It sheds its hide just once a year.",
  },
  {
    id: 77,
    name: "ponyta",
    types: ["fire"],
    height: 10,
    weight: 300,
    description:
      "As a newborn, it can barely stand. However, through galloping, its legs are made tougher and faster.",
  },
  {
    id: 78,
    name: "rapidash",
    types: ["fire"],
    height: 17,
    weight: 950,
    description:
      "When at an all-out gallop, its blazing mane sparkles, enhancing its beautiful appearance.",
  },
  {
    id: 79,
    name: "slowpoke",
    types: ["psychic", "water"],
    height: 12,
    weight: 360,
    description:
      "Although slow, it is skilled at fishing with its tail. It does not feel pain if its tail is bitten.",
  },
  {
    id: 80,
    name: "slowbro",
    types: ["psychic", "water"],
    height: 16,
    weight: 785,
    description:
      "Though usually dim witted, it seems to become inspired if the Shellder on its tail bites down.",
  },
  {
    id: 81,
    name: "magnemite",
    types: ["steel", "electric"],
    height: 3,
    weight: 60,
    description:
      "The electromagnetic waves emitted by the units at the sides of its head expel antigravity, which allows it to float.",
  },
  {
    id: 82,
    name: "magneton",
    types: ["steel", "electric"],
    height: 10,
    weight: 600,
    description:
      "The stronger electromagnetic waves from the three linked Magnemite are enough to dry out surrounding moisture.",
  },
  {
    id: 83,
    name: "farfetchd",
    types: ["flying", "normal"],
    height: 8,
    weight: 150,
    description:
      "It cant live without the stalk it holds. Thats why it defends the stalk from attackers with its life.",
  },
  {
    id: 84,
    name: "doduo",
    types: ["flying", "normal"],
    height: 14,
    weight: 392,
    description:
      "The brains in its two heads appear to communicate emotions to each other with a telepathic power.",
  },
  {
    id: 85,
    name: "dodrio",
    types: ["flying", "normal"],
    height: 18,
    weight: 852,
    description:
      "When Doduo evolves into this odd breed, one of its heads splits into two. It runs at nearly 40 mph.",
  },
  {
    id: 86,
    name: "seel",
    types: ["water"],
    height: 11,
    weight: 900,
    description:
      "The colder it gets, the better it feels. It joyfully swims around oceans so cold that they are filled with floating ice.",
  },
  {
    id: 87,
    name: "dewgong",
    types: ["ice", "water"],
    height: 17,
    weight: 1200,
    description:
      "Its streamlined body has low resistance, and it swims around cold oceans at a speed of eight knots.",
  },
  {
    id: 88,
    name: "grimer",
    types: ["poison"],
    height: 9,
    weight: 300,
    description:
      "Born from sludge, these Pokémon now gather in polluted places and increase the bacteria in their bodies.",
  },
  {
    id: 89,
    name: "muk",
    types: ["poison"],
    height: 12,
    weight: 300,
    description:
      "Its so stinky! Muks body contains toxic elements, and any plant will wilt when it passes by.",
  },
  {
    id: 90,
    name: "shellder",
    types: ["water"],
    height: 3,
    weight: 40,
    description:
      "It swims backward by opening and closing its two shells. Its large tongue is always kept hanging out.",
  },
  {
    id: 91,
    name: "cloyster",
    types: ["ice", "water"],
    height: 15,
    weight: 1325,
    description:
      "It fights by keeping its shell tightly shut for protection and by shooting spikes to repel foes.",
  },
  {
    id: 92,
    name: "gastly",
    types: ["poison", "ghost"],
    height: 13,
    weight: 1,
    description:
      "Born from gases, anyone would faint if engulfed by its gaseous body, which contains poison.",
  },
  {
    id: 93,
    name: "haunter",
    types: ["poison", "ghost"],
    height: 16,
    weight: 1,
    description:
      "It likes to lurk in the dark and tap shoulders with a gaseous hand. Its touch causes endless shuddering.",
  },
  {
    id: 94,
    name: "gengar",
    types: ["poison", "ghost"],
    height: 15,
    weight: 405,
    description:
      "The leer that floats in darkness belongs to a Gengar delighting in casting curses on people.",
  },
  {
    id: 95,
    name: "onix",
    types: ["ground", "rock"],
    height: 88,
    weight: 2100,
    description:
      "Opening its large mouth, it ingests massive amounts of soil and creates long tunnels.",
  },
  {
    id: 96,
    name: "drowzee",
    types: ["psychic"],
    height: 10,
    weight: 324,
    description:
      "It can tell what people are dreaming by sniffing with its big nose. It loves fun dreams.",
  },
  {
    id: 97,
    name: "hypno",
    types: ["psychic"],
    height: 16,
    weight: 756,
    description:
      "Seeing its swinging pendulum can induce sleep in three seconds, even in someone who just woke up.",
  },
  {
    id: 98,
    name: "krabby",
    types: ["water"],
    height: 4,
    weight: 65,
    description:
      "It lives in burrows dug on sandy beaches. Its pincers fully grow back if they are broken in battle.",
  },
  {
    id: 99,
    name: "kingler",
    types: ["water"],
    height: 13,
    weight: 600,
    description:
      "The larger pincer has 10,000- horsepower strength. However, it is so heavy, it is difficult to aim.",
  },
  {
    id: 100,
    name: "voltorb",
    types: ["electric"],
    height: 5,
    weight: 104,
    description:
      "It looks just like a Pok Ball. It is dangerous because it may electrocute or explode on contact.",
  },
  {
    id: 101,
    name: "electrode",
    types: ["electric"],
    height: 12,
    weight: 666,
    description:
      "It is known to drift on winds if it is bloated to bursting with stored electricity.",
  },
  {
    id: 102,
    name: "exeggcute",
    types: ["psychic", "grass"],
    height: 4,
    weight: 25,
    description:
      "Its six eggs converse using telepathy. They can quickly gather if they become separated.",
  },
  {
    id: 103,
    name: "exeggutor",
    types: ["psychic", "grass"],
    height: 20,
    weight: 1200,
    description:
      "It is called The Walking Jungle. If a head grows too big, it falls off and becomes an Exeggcute.",
  },
  {
    id: 104,
    name: "cubone",
    types: ["ground"],
    height: 4,
    weight: 65,
    description:
      "When it thinks of its dead mother, it cries. Its crying makes the skull it wears rattle hollowly.",
  },
  {
    id: 105,
    name: "marowak",
    types: ["ground"],
    height: 10,
    weight: 450,
    description:
      "From its birth, this savage Pokémon constantly holds bones. It is skilled in using them as weapons.",
  },
  {
    id: 106,
    name: "hitmonlee",
    types: ["fighting"],
    height: 15,
    weight: 498,
    description:
      "Its legs can stretch double. First-time foes are startled by its extensible reach.",
  },
  {
    id: 107,
    name: "hitmonchan",
    types: ["fighting"],
    height: 14,
    weight: 502,
    description:
      "The arm-twisting punches it throws pulverize even concrete. It rests after three minutes of fighting.",
  },
  {
    id: 108,
    name: "lickitung",
    types: ["normal"],
    height: 12,
    weight: 655,
    description:
      "Being licked by its long, saliva-covered tongue leaves a tingling sensation. Extending its tongue retracts its tail.",
  },
  {
    id: 109,
    name: "koffing",
    types: ["poison"],
    height: 6,
    weight: 10,
    description:
      "Toxic gas is held within its thin, balloon-shaped body, so it can cause massive explosions.",
  },
  {
    id: 110,
    name: "weezing",
    types: ["poison"],
    height: 12,
    weight: 95,
    description:
      "Inhaling toxic fumes from trash and mixing them inside its body lets it spread an even fouler stench.",
  },
  {
    id: 111,
    name: "rhyhorn",
    types: ["rock", "ground"],
    height: 10,
    weight: 1150,
    description:
      "Its powerful tackles can destroy anything. However, it is too slow witted to help people work.",
  },
  {
    id: 112,
    name: "rhydon",
    types: ["rock", "ground"],
    height: 19,
    weight: 1200,
    description:
      "Standing on its hind legs freed its forelegs and made it smarter. It is very forgetful, however.",
  },
  {
    id: 113,
    name: "chansey",
    types: ["normal"],
    height: 11,
    weight: 346,
    description:
      "A kindly Pokémon that lays highly nutritious eggs and shares them with injured Pokmon or people.",
  },
  {
    id: 114,
    name: "tangela",
    types: ["grass"],
    height: 10,
    weight: 350,
    description:
      "Many writhing vines cover it, so its true identity remains unknown. The blue vines grow its whole life long.",
  },
  {
    id: 115,
    name: "kangaskhan",
    types: ["normal"],
    height: 22,
    weight: 800,
    description:
      "It raises its offspring in its belly pouch. It lets the baby out to play only when it feels safe.",
  },
  {
    id: 116,
    name: "horsea",
    types: ["water"],
    height: 4,
    weight: 80,
    description:
      "It makes its nest in the shade of corals. If it senses danger, it spits murky ink and flees.",
  },
  {
    id: 117,
    name: "seadra",
    types: ["water"],
    height: 12,
    weight: 250,
    description:
      "Its spines provide protection. Its fins and bones are prized as traditional-medicine ingredients.",
  },
  {
    id: 118,
    name: "goldeen",
    types: ["water"],
    height: 6,
    weight: 150,
    description:
      "Though it appears very elegant when swimming with fins unfurled, it can jab powerfully with its horn.",
  },
  {
    id: 119,
    name: "seaking",
    types: ["water"],
    height: 13,
    weight: 390,
    description:
      "In autumn, its body becomes more fatty in preparing to propose to a mate. It takes on beautiful colors.",
  },
  {
    id: 120,
    name: "staryu",
    types: ["water"],
    height: 8,
    weight: 345,
    description:
      "As long as its red core remains, it can regenerate its body instantly, even if its torn apart.",
  },
  {
    id: 121,
    name: "starmie",
    types: ["psychic", "water"],
    height: 11,
    weight: 800,
    description:
      "Its core shines in many colors and sends radio signals into space to communicate with something.",
  },
  {
    id: 122,
    name: "mr-mime",
    types: ["fairy", "psychic"],
    height: 13,
    weight: 545,
    description:
      "It shapes an invisible wall in midair by minutely vibrating its fingertips to stop molecules in the air.",
  },
  {
    id: 123,
    name: "scyther",
    types: ["flying", "bug"],
    height: 15,
    weight: 560,
    description:
      "The sharp scythes on its forearms become increasingly sharp by cutting through hard objects.",
  },
  {
    id: 124,
    name: "jynx",
    types: ["psychic", "ice"],
    height: 14,
    weight: 406,
    description:
      "Its cries sound like human speech. However, it is impossible to tell what it is trying to say.",
  },
  {
    id: 125,
    name: "electabuzz",
    types: ["electric"],
    height: 11,
    weight: 300,
    description:
      "Research is progressing on storing lightning in Electabuzz so this energy can be used at any time.",
  },
  {
    id: 126,
    name: "magmar",
    types: ["fire"],
    height: 13,
    weight: 445,
    description:
      "The scorching fire exhaled by Magmar forms heat waves around its body, making it hard to see the Pokémon clearly.",
  },
  {
    id: 127,
    name: "pinsir",
    types: ["bug"],
    height: 15,
    weight: 550,
    description:
      "It grips prey with its powerful pincers and will not let go until the prey is torn in half.",
  },
  {
    id: 128,
    name: "tauros",
    types: ["normal"],
    height: 14,
    weight: 884,
    description:
      "Once it takes aim at its foe, it makes a headlong charge. It is famous for its violent nature.",
  },
  {
    id: 129,
    name: "magikarp",
    types: ["water"],
    height: 9,
    weight: 100,
    description:
      "A Magikarp living for many years can leap a mountain using Splash. The move remains useless, though.",
  },
  {
    id: 130,
    name: "gyarados",
    types: ["flying", "water"],
    height: 65,
    weight: 2350,
    description:
      "Once it begins to rampage, a Gyarados will burn everything down, even in a harsh storm.",
  },
  {
    id: 131,
    name: "lapras",
    types: ["ice", "water"],
    height: 25,
    weight: 2200,
    description:
      "Able to understand human speech and very intelligent, it loves to swim in the sea with people on its back.",
  },
  {
    id: 132,
    name: "ditto",
    types: ["normal"],
    height: 3,
    weight: 40,
    description:
      "It can reconstitute its entire cellular structure to change into what it sees, but it returns to normal when it relaxes.",
  },
  {
    id: 133,
    name: "eevee",
    types: ["normal"],
    height: 3,
    weight: 65,
    description:
      "Thanks to its unstable genetic makeup, this special Pokémon conceals many different possible evolutions.",
  },
  {
    id: 134,
    name: "vaporeon",
    types: ["water"],
    height: 10,
    weight: 290,
    description:
      "Its cell composition is similar to water molecules. As a result, it cant be seen when it melts away into water.",
  },
  {
    id: 135,
    name: "jolteon",
    types: ["electric"],
    height: 8,
    weight: 245,
    description:
      "By storing electricity in its body, it can shoot its bristlelike fur like a barrage of missiles.",
  },
  {
    id: 136,
    name: "flareon",
    types: ["fire"],
    height: 9,
    weight: 250,
    description:
      "Inhaled air is carried to its flame sac, heated, and exhaled as fire that reaches over 3,000 degrees F.",
  },
  {
    id: 137,
    name: "porygon",
    types: ["normal"],
    height: 8,
    weight: 365,
    description:
      "A man-made Pokémon created using advanced scientific means. It can move freely in cyberspace.",
  },
  {
    id: 138,
    name: "omanyte",
    types: ["water", "rock"],
    height: 4,
    weight: 75,
    description:
      "A Pokémon that was resurrected from a fossil using modern science. It swam in ancient seas.",
  },
  {
    id: 139,
    name: "omastar",
    types: ["water", "rock"],
    height: 10,
    weight: 350,
    description:
      "It is thought that this Pokémon became extinct because its spiral shell grew too large.",
  },
  {
    id: 140,
    name: "kabuto",
    types: ["water", "rock"],
    height: 5,
    weight: 115,
    description:
      "It is thought to have inhabited beaches 300 million years ago. It is protected by a stiff shell.",
  },
  {
    id: 141,
    name: "kabutops",
    types: ["water", "rock"],
    height: 13,
    weight: 405,
    description:
      "It is thought that this Pokémon came onto land because its prey adapted to life on land.",
  },
  {
    id: 142,
    name: "aerodactyl",
    types: ["flying", "rock"],
    height: 18,
    weight: 590,
    description:
      "A Pokémon that roamed the skies in the dinosaur era. Its teeth are like saw blades.",
  },
  {
    id: 143,
    name: "snorlax",
    types: ["normal"],
    height: 21,
    weight: 4600,
    description:
      "When its belly is full, it becomes too lethargic to even lift a finger, so it is safe to bounce on its belly.",
  },
  {
    id: 144,
    name: "articuno",
    types: ["flying", "ice"],
    height: 16,
    weight: 554,
    description:
      "A legendary bird Pokémon. It can create blizzards by freezing moisture in the air.",
  },
  {
    id: 145,
    name: "zapdos",
    types: ["flying", "electric"],
    height: 0,
    weight: 0,
    description:
      "A legendary Pokémon that is said to live in thunderclouds. It freely controls lightning bolts.",
  },
  {
    id: 146,
    name: "moltres",
    types: ["flying", "fire"],
    height: 20,
    weight: 600,
    description:
      "One of the legendary bird Pokémon. It is said that its appearance indicates the coming of spring.",
  },
  {
    id: 147,
    name: "dratini",
    types: ["dragon"],
    height: 18,
    weight: 33,
    description:
      "It is called the Mirage Pokémon because so few have seen it. Its shed skin has been found.",
  },
  {
    id: 148,
    name: "dragonair",
    types: ["dragon"],
    height: 40,
    weight: 165,
    description:
      "If its body takes on an aura, the weather changes instantly. It is said to live in seas and lakes.",
  },
  {
    id: 149,
    name: "dragonite",
    types: ["flying", "dragon"],
    height: 22,
    weight: 2100,
    description:
      "It is said to make its home somewhere in the sea. It guides crews of shipwrecks to shore.",
  },
  {
    id: 150,
    name: "mewtwo",
    types: ["psychic"],
    height: 20,
    weight: 1220,
    description:
      "A Pokémon created by recombining Mews genes. Its said to have the most savage heart among Pokmon.",
  },
  {
    id: 151,
    name: "mew",
    types: ["psychic"],
    height: 4,
    weight: 40,
    description:
      "Mew is said to possess the genetic composition of all Pokémon. It is capable of making itself invisible at will, so it entirely avoids notice even if it approaches people.",
  },
];
